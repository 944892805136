<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="editWarningUser">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
                <el-form-item label="群组名称" prop="name">
                    <el-input style="width: 400px" v-model="form.name" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="通知范围" prop="isGlobal">
                    <el-radio-group v-model="form.isGlobal">
                        <el-radio :label="0">{{ '区域' }}</el-radio>
                        <el-radio :label="1">{{ '全局' }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="群组范围" label-width="120px" v-if="!form.isGlobal">
                    <el-button icon="el-icon-plus" type="primary" @click="addCity">添加群组范围</el-button>
                </el-form-item>
                <el-form-item label-width="0px" v-if="!form.isGlobal">
                    <el-table
                        :data="cityData"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                        <el-table-column
                            label="群组范围"
                            min-width="120">
                            <template slot-scope="{ row }">
                                <div class="groupUsersBox">
                                    <span v-if="row.regionName">{{ row.regionName }}</span>
                                    <span v-if="row.companyName">商户：{{ row.companyName }}</span>
                                    <span v-if="row.incomeName">项目：{{ row.incomeName }}</span>
                                    <span v-if="row.stationName">站点：{{ row.stationName }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="群组人员"
                            min-width="180">
                            <template slot-scope="scope">
                                <div class="groupUsersBox">
                                    <div class="a-flex-rfsc">
                                        <el-input 
                                            v-model="scope.row.phone" 
                                            maxlength="20" 
                                            placeholder="请输入发送人电话" 
                                            @focus="scope.row.isEdit = true" 
                                            @blur="scope.row.isEdit= false"
                                            @keyup.enter.native="addUser(scope.row,scope.$index)"></el-input>
                                        <i class="el-icon-circle-plus-outline a-c-blue a-fs-18" style="margin-left: 10px" @click="addUser(scope.row,scope.$index)"></i>
                                    </div>
                                    <div class="a-flex-rfsc a-flex-wrap a-mt-10" v-if="scope.row.groupUsers && scope.row.groupUsers.length">
                                        <div class="a-flex-rfsc send-user" v-for="(item,index) in scope.row.groupUsers" :key="index">
                                            <span class="send-user-name">{{ item.userName }} {{ item.mobile }}（{{ item.companyName }}）</span>
                                            <!-- <span class="send-user-line"></span> -->
                                            <i class="el-icon-close a-c-white" @click="removeUserNoGlobal(scope.$index,index)"></i>
                                        </div>
                                    </div>
                                </div>
                                
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            width="90">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editCityItem(scope.row,scope.$index)" v-if="!scope.row.isNational" />
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <img src="../../assets/icon/option-refuse.png" class="a-wh-16 a-ml-12" @click="removeCityItem(scope.$index)" v-if="!scope.row.isNational" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="通知人" v-if="form.isGlobal">
                    <div class="a-flex-rfsc">
                        <el-input @keyup.enter.native="addUser" style="width: 300px;margin-right: 12px;" v-model="phone" maxlength="20" placeholder="请输入通知人手机号"></el-input>
                        <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addUser">添加</el-button>
                    </div>
                    <div class="a-flex-rfsc a-flex-wrap a-mt-20" style="width: 800px;">
                        <div class="a-flex-rfsc send-user" v-for="(item,index) in sendUserList.groupUsers" :key="index">
                            <span class="send-user-name">{{ item.userName }} {{ item.mobile }}（{{ item.companyName }}）</span>
                            <!-- <span class="send-user-line"></span> -->
                            <i class="el-icon-close a-c-white" @click="removeUserIsGlobal(index)"></i>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <choose-range ref="chooseRange" @rangeChange="rangeChange"></choose-range>
        <choose-user ref="chooseUser" @success="handleChooseUser"></choose-user>
    </div>
</template>

<script>
    import {
		CodeToText,
	} from 'element-china-area-data'
    import chooseRange from './child/chooseRange.vue';
    import chooseUser from './child/chooseUser.vue';
    export default {
    components: { chooseRange, chooseUser },
        data() {
            return {
                groupId: '',
                provinceCode: '',
                cityCode: '',
                areaCode: '',
                companyId: '',
                incomeId: '',
                stationId: '',
                phone: '',
                form: {
                    name: '',
                    isGlobal: 0,//是否全局,0否,1是
                },
                rules: {
                    name: [{required: true, message:'请输入群组名称', trigger: 'blur'}]
                },
                sendUserList: {
                    isNational: 0,
                    isGlobal: 1,
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    companyId: '',
                    incomeId: '',
                    stationId: '',
                    groupUsers: []
                }, //全局通知人
                cityData: [{   // 区域通知人
                    isEdit: false,
                    provinceCode: '',
                    isNational: 1, //是否全国:1是,0否
                    isGlobal: 0,
                    cityCode: '',
                    areaCode: '',
                    provinceName: '',
                    cityName: '',
                    areaName: '',
                    regionName: '全国',
                    companyId: '',
                    incomeId: '',
                    stationId: '',
                    companyName: '',
                    incomeName: '',
                    stationName: '',
                    phone: '',
                    groupUsers: []
                }],
                currentIndex: ''
            };
        },
        created () {
            this.groupId = this.$route.query.groupId
            if(this.groupId) this.getWarningGroupDetails()
        },
        computed: {
            
        },
        mounted () {
            
        },
        methods: {
            // 查询群组详情
            getWarningGroupDetails () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWarningGroupDetails,
                    method: "post",
                    params: {
                        id: this.groupId
                    },
                })
                .then(res=>{
                    let datas = res.result.data
                    this.form = {
                        name: datas.groupName,
                        isGlobal: datas.groupInfo[0].isGlobal
                    }
                    if(this.form.isGlobal){
                        this.sendUserList = datas.groupInfo[0]
                    }else{
                        this.cityData = datas.groupInfo.map(item=>{
                            let regionName = (item.provinceCode?CodeToText[item.provinceCode]:"") + 
                                (item.cityCode ? '/' + CodeToText[item.cityCode] : '') +  
                                (item.areaCode ? '/' + CodeToText[item.areaCode] : '')
                            return {
                                ...item,
                                regionName: item.isNational?'全国':regionName,
                                isEdit: false
                            }
                        })
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            addCity () {
                this.$refs['chooseRange'].dialogVisible = true
            },
            // 添加群组范围
            rangeChange (datas) {
                let elementKey = datas.provinceCode + datas.cityCode + datas.areaCode + datas.companyId + datas.incomeId + datas.stationId
                elementKey = JSON.stringify(elementKey)
                elementKey = elementKey.replaceAll("undefined", "");
                elementKey = elementKey.replaceAll("null", "");
                let item = this.cityData.find(res=>{
                    let itemKey = res.provinceCode + res.cityCode + res.areaCode + res.companyId + res.incomeId + res.stationId
                    itemKey = JSON.stringify(itemKey)
                    itemKey = itemKey.replaceAll("undefined", "");
                    itemKey = itemKey.replaceAll("null", "");
                    return itemKey == elementKey
                })
                if(item){
                    this.$message.error('当前群组范围已存在，请勿重复添加。')
                    this.cityData = this.moveElementToFirst(this.cityData, item)
                    this.cityData[0].isEdit = true
                    this.cityData = this.cityData.map(item=>{ // 将数组元素中的修改状态初始化
                        return {
                            ...item,
                            isUpdate: false
                        }
                    })
                    return
                }
                let updateItem = this.cityData.find(res=>{
                    return res.isUpdate == true
                })
                if(updateItem){
                    this.cityData = this.moveElementToFirst(this.cityData, updateItem)
                    this.cityData.splice(0,1);
                    this.cityData.unshift({
                        ...datas,
                        isGlobal: this.form.isGlobal,
                        isNational: 0,
                        isEdit: false,
                        phone: '',
                    })
                    this.cityData = this.cityData.map(item=>{ // 将数组元素中的修改状态初始化
                        return {
                            ...item,
                            isUpdate: false
                        }
                    })
                    return
                }
                this.cityData[0].isEdit = false
                this.cityData.unshift({
                    ...datas,
                    isGlobal: this.form.isGlobal,
                    isNational: 0,
                    isEdit: false,
                    phone: '',
                })
            },
            removeCityItem (index) {
                this.$confirm('是否确认删除？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.cityData.splice(index,1);
                }).catch(_=>{ })
            },
            editCityItem (datas, index) {
                this.cityData[index].isUpdate = true
                this.$refs['chooseRange'].form = {
                    provinceCode: datas.provinceCode,
                    cityCode: datas.cityCode,
                    areaCode: datas.areaCode,
                    company: {
                        label: datas.companyName,
                        value: datas.companyId
                    },
                    income: {
                        label: datas.incomeName,
                        value: datas.incomeId
                    },
                    station: {
                        label: datas.stationName,
                        value: datas.stationId
                    },
                }
                this.$refs['chooseRange'].groupUsers = datas.groupUsers
                this.$refs['chooseRange'].dialogVisible = true
            },
            addUser (item, index) {
                this.currentIndex = index
                if(!item.phone && !this.form.isGlobal){
                    this.$message.error('请先填写手机号')
                    return
                }
                if(!this.phone && this.form.isGlobal){
                    this.$message.error('请先填写手机号')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.checkUser,
                    method: "post",
                    params: {
                        mobile: !this.form.isGlobal?item.phone:this.phone
                    },
                })
                .then(res=>{
                    if (res.result.data && res.result.data.length) {
                        if(!this.form.isGlobal){
                            let user
                            let companyId = this.cityData[index].companyId || ''
                            if(companyId){
                                user = res.result.data.find(re=>{
                                    return re.companyId == companyId
                                })
                                if(!user){
                                    this.$confirm('该商户下未查询到此用户，是否去添加用户？', '温馨提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(_=>{
                                        this.$router.push({
                                            path: '/staffManage/staff-add'
                                        })
                                    }).catch(_=>{})
                                    return
                                }
                            }else if(res.result.data && res.result.data.length > 1){
                                this.$refs['chooseUser'].tableData = res.result.data
                                this.$refs['chooseUser'].dialogVisible = true
                                return
                            }else{
                                user = res.result.data[0]
                            }
                            let userItem = this.cityData[index].groupUsers.find(res=>{
                                return res.mobile == user.mobile && res.companyId == user.companyId
                            })
                            if(userItem){
                                this.$message.error('已添加该用户')
                                return
                            }
                            this.cityData[index].groupUsers.push({
                                mobile: user.mobile,
                                userId: user.userId,
                                userName: user.userName,
                                companyId: user.companyId,
                                companyName: user.companyName
                            })
                            this.cityData[index].phone = ''
                        }else{
                            let user
                            if(res.result.data && res.result.data.length > 1){
                                this.$refs['chooseUser'].tableData = res.result.data
                                this.$refs['chooseUser'].dialogVisible = true
                                return
                            }else{
                                user = res.result.data[0]
                            }
                            let userItem = this.sendUserList.groupUsers.find(res=>{
                                return res.mobile == user.mobile && res.companyId == user.companyId
                            })
                            if(userItem){
                                this.$message.error('已添加该用户')
                                return
                            }
                            this.sendUserList.groupUsers.push({
                                mobile: user.mobile,
                                userId: user.userId,
                                userName: user.userName,
                                companyId: user.companyId,
                                companyName: user.companyName
                            })
                            this.phone = ''
                        }
                        
                    } else {
                        this.$confirm('未查询到用户，是否去添加用户？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$router.push({
                                path: '/staffManage/staff-add'
                            })
                        }).catch(_=>{})
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            // 选择商户用户
            handleChooseUser (datas) {
                if(this.form.isGlobal){
                    datas.map(item=>{
                        this.sendUserList.groupUsers.push({
                            mobile: item.mobile,
                            userId: item.userId,
                            userName: item.userName,
                            companyId: item.companyId,
                            companyName: item.companyName
                        })
                    })
                    // this.sendUserList.groupUsers = [...new Set(this.sendUserList.groupUsers)]
                    this.sendUserList.groupUsers = this.uniqueArr(this.sendUserList.groupUsers)
                    this.phone = ''
                }else{
                    datas.map(item=>{
                        this.cityData[this.currentIndex].groupUsers.push({
                            mobile: item.mobile,
                            userId: item.userId,
                            userName: item.userName,
                            companyId: item.companyId,
                            companyName: item.companyName
                        })
                    })
                    // this.cityData[this.currentIndex].groupUsers = [...new Set(this.cityData[this.currentIndex].groupUsers)]
                    this.cityData[this.currentIndex].groupUsers = this.uniqueArr(this.cityData[this.currentIndex].groupUsers)
                    
                    this.cityData[this.currentIndex].phone = ''
                }
            },
            removeUserNoGlobal (index,idx) {
                this.$confirm('是否确认删除？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.cityData[index].groupUsers.splice(idx,1)
                }).catch(_=>{})
            },
            removeUserIsGlobal (index) {
                this.$confirm('是否确认删除？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.sendUserList.groupUsers.splice(index,1)
                }).catch(_=>{})
            },
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let checkFormResult = await this.checkForm()
                        if(!checkFormResult){
                            return
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            let reqData
                            if(this.form.isGlobal){
                                reqData = {
                                    ...this.form,
                                    id: this.groupId,
                                    alertGroups: [this.sendUserList]
                                }
                            }else{
                                reqData = {
                                    ...this.form,
                                    id: this.groupId,
                                    alertGroups: [...this.cityData]
                                }
                            }
                            this.$Axios._post({
                                url: this.$Config.apiUrl.warningAddUserGroup,
                                method: "post",
                                params: reqData,
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$router.back()
                                    this.$store.dispatch("delView", this.$route);
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(err=>{ console.log(err); })
                    }
                })
                
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            moveElementToFirst(arr, element) {
                const index = arr.indexOf(element);
                arr.map((item,index)=>{
                    let itemKey = item.provinceCode + item.cityCode + item.areaCode + item.companyId + item.incomeId + item.stationId
                    let elementKey = element.provinceCode + element.cityCode + element.areaCode + element.companyId + element.incomeId + element.stationId
                    if(itemKey == elementKey){
                        arr.splice(index, 1);
                        arr.unshift(item);
                    }
                })
                return arr;
            },
            tableRowClassName({row, rowIndex}) {
                if (row.isEdit) {
                    return 'edit-city';
                }
                return '';
            },
            checkForm () {
                return new Promise((resolve, reject) => {
                    if(this.form.isGlobal){
                        if(!this.sendUserList.groupUsers.length){
                            this.$message.error('请至少填写一位通知人员')
                            resolve(false)
                            return
                        }
                        resolve(true)
                    }else{
                        for (let index = 0; index < this.cityData.length; index++) {
                            const item = this.cityData[index].groupUsers;
                            if(!item.length){
                                this.$message.error('请完整填写群组通知人员')
                                this.cityData[index].isEdit = true
                                resolve(false)
                                return
                            }
                        }
                        resolve(true)
                    }
                })
                
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].mobile==arr[j].mobile && arr[i].companyId==arr[j].companyId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table .edit-city {
        background: #FDE2E2 !important;
    }
    .editWarningUser{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        
        .groupUsersBox{
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            width: 100%;
            height: 100%;
            .a-mt-10{
                margin-top: 10px;
            }
        }
        .city-item{
            width: 220px;
            margin: 0px 10px 15px;
            height: 260px;
            border-radius: 4px;
            border: 1px solid #E4E7ED;
            position: relative;
        }
        .city-item-title{
            background: #409EFF;
            color: #ffffff;
            font-size: 12px;
            padding: 6px 10px;
            border-radius: 4px 4px 0 0;
            line-height: 18px;
        }
        .city-item-content{
            padding: 10px 10px 0;
            height: calc(100% - 40px);
            overflow-y: scroll;
        }
        .removeAreaItem{
            font-size: 18px;
            color: #ffffff;
        }
        .city-item-content::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        .send-user{
            background: #53A8FF;
            color: #ffffff;
            border-radius: 4px;
            padding: 0 10px;
            margin: 0 10px 10px 0;
            .send-user-line{
                width: 1px;
                height: 16px;
                // background: #ffffff;
                margin: 0 5px;
            }
        }
    }
</style>